import React, { ReactElement, useState } from "react"
import moment from "moment"
import { useQuery, useMutation, useLazyQuery } from "@apollo/client"

import Select from "components/Form/Select"
import generateCompaniesOptions from "utils/formik/generateCompaniesOptions"
import { getCompanies, getCompany, GetCompanyData } from "services/graphql/queries/company.queries"
import { PageWrapper } from "components/Layout/Structure"
import PageTitle from "components/Title"
import {
  CompanyBillingPaymentsData,
  CompanyBillingPaymentsVariables,
  CompanyBillingStatsData,
  CompanyBillingStatsVariables,
  CompanyCurrentBillData,
  CompanyCurrentBillVariables,
  getCompanyBillingPayments,
  getCompanyBillingStats,
  getCompanyCurrentBill,
} from "services/graphql/queries/company-accountancy.queries"
import { HorizontalSelector, HorizontalSelectorChip } from "components/HorizontalSelector/HorizontalSelector"
import { BillingHeader } from "components/Companies/Billing/Billing.styled"
import { BillingStats } from "components/Companies/Billing/BillingStats"
import { CompanyPaymentTable } from "components/Companies/Billing/CompanyPaymentTable"
import PricingTable from "components/Companies/Billing/PricingTable"
import { CompanyBillingPaymentStatus } from "types/company-billing.types"
import {
  updateCompanyBillingPayment,
  UpdateCompanyBillingPaymentData,
  UpdateCompanyBillingPaymentVariables,
} from "services/graphql/mutations/company-billing-payment.mutations"

export function Billing(): ReactElement {
  const [year, setYear] = useState(moment().format("YYYY"))
  const [selectedCompany, setSelectedCompany] = useState({ value: null, label: "" })
  const [pricingDisplayed, displayPricing] = useState(false)

  /** GQL QUERIES */
  const { data: companies } = useQuery(getCompanies)
  const [getCompanyQuery, { data: companyData, loading: companyLoading }] = useLazyQuery<GetCompanyData>(getCompany)
  const [getCompanyBillingPaymentsQuery, { data: cbpData, loading: cbpLoading }] = useLazyQuery<
    CompanyBillingPaymentsData,
    CompanyBillingPaymentsVariables
  >(getCompanyBillingPayments)
  const [getCompanyBillingStatsQuery, { data: cbStatsData, loading: cbStatsLoading }] = useLazyQuery<
    CompanyBillingStatsData,
    CompanyBillingStatsVariables
  >(getCompanyBillingStats)
  const [getCompanyCurrentBillQuery, { data: currentBillData, loading: currentBillLoading }] = useLazyQuery<
    CompanyCurrentBillData,
    CompanyCurrentBillVariables
  >(getCompanyCurrentBill)
  const [updateCompanyBillingPaymentMutation] = useMutation<
    UpdateCompanyBillingPaymentData,
    UpdateCompanyBillingPaymentVariables
  >(updateCompanyBillingPayment)

  const _onChange = e => {
    // Change company & Reset year
    setSelectedCompany(e)
    setYear(moment().format("YYYY"))

    getCompanyQuery({ variables: { id: e.value } })
    getCompanyBillingPaymentsQuery({ variables: { companyId: e.value, year: year } })
    getCompanyBillingStatsQuery({ variables: { companyId: e.value } })
    getCompanyCurrentBillQuery({ variables: { companyId: e.value } })
  }

  const _changeYear = (_year: string) => {
    setYear(_year)
    displayPricing(false)

    getCompanyBillingPaymentsQuery({ variables: { companyId: selectedCompany.value, year: _year } })
  }

  const _updateCompanyBillingPayment = (id: string, status: CompanyBillingPaymentStatus) => {
    updateCompanyBillingPaymentMutation({ variables: { id, status } })
  }

  return (
    <PageWrapper>
      <PageTitle>🧾 Facturation</PageTitle>

      <BillingHeader>
        <Select
          value={selectedCompany}
          onChange={_onChange}
          options={!!companies ? generateCompaniesOptions(companies.getCompanies) : []}
          placeholder="Choisir"
          label="Transporteur"
          menuPosition="fixed"
        />
      </BillingHeader>

      {selectedCompany.value && (
        <>
          <BillingStats loading={cbStatsLoading} stats={cbStatsData?.getCompanyBillingStats} />

          <HorizontalSelector>
            <HorizontalSelectorChip selected={!pricingDisplayed && year === "2023"} onClick={() => _changeYear("2023")}>
              2023
            </HorizontalSelectorChip>
            <HorizontalSelectorChip selected={!pricingDisplayed && year === "2022"} onClick={() => _changeYear("2022")}>
              2022
            </HorizontalSelectorChip>
            <HorizontalSelectorChip selected={!pricingDisplayed && year === "2021"} onClick={() => _changeYear("2021")}>
              2021
            </HorizontalSelectorChip>
            <HorizontalSelectorChip selected={pricingDisplayed} onClick={() => displayPricing(!pricingDisplayed)}>
              Grille des tarifs
            </HorizontalSelectorChip>
          </HorizontalSelector>

          {!pricingDisplayed && (
            <>
              {Number(year) === moment().year() && (
                <CompanyPaymentTable loading={currentBillLoading} payments={[currentBillData?.getCompanyCurrentBill]} />
              )}

              <CompanyPaymentTable
                loading={cbpLoading}
                payments={cbpData?.getCompanyBillingPayments}
                updatePaymentStatus={_updateCompanyBillingPayment}
              />
            </>
          )}

          {selectedCompany.value && pricingDisplayed && (
            <PricingTable loading={companyLoading} pricing={companyData?.getCompany?.accountancy?.pricing} />
          )}
        </>
      )}
    </PageWrapper>
  )
}

export default Billing
