import React, { ReactElement, useState } from "react"
import { useDispatch } from "react-redux"
import { Button, Col, Row } from "reactstrap"
import { Input, Loader } from "semantic-ui-react"
import styled from "styled-components"
import { toast } from "react-toastify"
import { Dropdown } from "semantic-ui-react"

import Title from "components/Title"
import Select from "components/Form/Select"
import FilterBarZipcodes from "components/FilterBarZipcodes/FilterBarZipcodes"
import Search from "components/SearchBar/SearchBar"
import {
  apiGetNbKeepersForPushNotifications,
  apiSearchTestKeepers,
  apiSendPushNotifications,
  apiSendTestPushNotification,
} from "services/push-notifications"
import { ZipcodesData } from "types/filter-bar-zipcodes.types"
import { KeeperStatus } from "types/keeper.types"
import { COLOR } from "utils/color"

const LabelInput = styled.div`
  font-size: 0.8em;
  margin-bottom: 3px;
  color: ${COLOR.MEDIUM_GREY};
`

const StatsOutput = styled.div`
  font-size: 12px;
`

const Subtitle = styled.div`
  margin-bottom: 10px;
  font-size: 17px;
`

const HelpInputText = styled.div`
  font-size: 8px;
  margin-top: 2px;
`

type SelectData = {
  value: string
  label: string
}

const deeplinkPrefix = process.env.REACT_APP_DEEPLINK_PREFIX
// used as defaultValue
const deeplinkToDashboard = `${deeplinkPrefix}://dashboard`

// values are deeplinks configurated on keeper-app for expo-notifications
const notificationRedirectionOptions = [
  { key: "Dashboard", text: "Dashboard", value: deeplinkToDashboard },
  { key: "Agenda", text: "Agenda", value: `${deeplinkPrefix}://agenda` },
  { key: "Sponsor", text: "Sponsor", value: `${deeplinkPrefix}://Sponsorship` },
]

export function PushNotifications(): ReactElement {
  const dispatch = useDispatch()
  const [loading] = useState(false)
  const [statuses, setStatuses] = useState<SelectData[]>([])
  const [zipcodes, setZipcodes] = useState<SelectData[]>([])
  const [keepersAvailable, setKeepersAvailable] = useState<{
    loaded: boolean
    nbKeepers: number
    nbKeepersActive: number
  }>({
    loaded: false,
    nbKeepers: null,
    nbKeepersActive: null,
  })
  const [testKeeperId, setTestKeeperId] = useState<string>(null)
  const [testDone, setTestDone] = useState(false)
  const [notificationData, setNotificationData] = useState({
    title: "",
    content: "",
    data: { redirectTo: deeplinkToDashboard },
  })
  const [notificationSent, setNotificationSent] = useState(false)

  /**
   * Get how many Keepers have push notifications activated with these filters
   */
  const getNbKeepersForPushNotifications = async () => {
    const r = await dispatch(
      apiGetNbKeepersForPushNotifications({
        statuses: statuses.map(s => s.value).join(","),
        zipcodes: zipcodes.map(z => z.value).join(","),
      }),
    )

    const data: { nbKeepers: number; nbKeepersActive: number } = r?.payload.data
    setKeepersAvailable({ loaded: true, ...data })
  }

  /**
   * Get test Keepers to test this notification
   * @param input - Search input
   * @returns List of Keepers
   */
  const getTestKeepers = async (input: string) => {
    if (input.length > 2) {
      const r = await dispatch(apiSearchTestKeepers({ input }))
      return r.payload.data.map(k => ({
        value: k.id,
        label: `😎 ${k.firstName} ${k.lastName} (${k.email})`,
        type: "KEEPER",
      }))
    }
    return []
  }

  const _onChangeKeepers = (keeper: { label: string; type: string; value: string }) => {
    setTestKeeperId(keeper.value)
  }

  /**
   * Send test notification to the test Keeper.
   * Will disable the notification content edition.
   */
  const sendTestNotification = async () => {
    try {
      const r = await dispatch(apiSendTestPushNotification({ keeperId: testKeeperId, ...notificationData }))
      const success = r.payload?.data

      if (!success) throw Error("Error while sending test notification")

      setTestDone(true)
    } catch (e) {
      setTestDone(false)
    }
  }

  /**
   * Send a rocket by notifying every Keeper concerned.
   */
  const sendPushNotifications = async () => {
    setNotificationSent(true)

    await dispatch(
      apiSendPushNotifications({
        statuses: statuses.map(s => s.value),
        zipcodes: zipcodes.map(z => z.value),
        ...notificationData,
      }),
    )

    toast.success("Notification envoyée !")

    // Full reset of the form
    setKeepersAvailable({
      loaded: false,
      nbKeepers: null,
      nbKeepersActive: null,
    })
    setTestKeeperId(null)
    setTestDone(false)
    setNotificationData({ title: "", content: "", data: { redirectTo: "" } })
  }

  const resetForm = () => {
    setNotificationData({ title: "", content: "", data: { redirectTo: "" } })
    setTestKeeperId(null)
    setTestDone(false)
  }

  return (
    <div style={{ flex: 1, padding: 10 }}>
      <div style={{ padding: 30 }}>
        <Title>📲 Envoi de notifications push</Title>

        <p>
          Envoyer à un ou plusieurs Keepers des notifications push, pour ceux ayant activé leurs notifications push via
          l&apos;application Pickme !
        </p>

        <Row style={{ marginTop: "3em" }}>
          <Col xs={12}>
            <Subtitle>1️⃣ Rechercher les Keepers</Subtitle>
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={6}>
            <Select
              menuPosition="fixed"
              defaultValue={[]}
              isMulti
              name="keepers_statuses"
              options={Object.keys(KeeperStatus).map(i => ({ label: i, value: i }))}
              placeholder="Filtrer par statut..."
              onChange={e => setStatuses(e)}
              label={"Statut des Keepers"}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "1em" }}>
          <Col xs={12} md={12}>
            <LabelInput>Zone des Keepers</LabelInput>
            <FilterBarZipcodes onChangeZipcodes={(zipcodes: ZipcodesData[]) => setZipcodes(zipcodes)} />
          </Col>
        </Row>

        <Row>
          {!keepersAvailable.loaded && (
            <Col xs={12} md={12}>
              {loading ? (
                <Loader active inline />
              ) : (
                <Button
                  disabled={statuses.length === 0 || zipcodes.length === 0}
                  onClick={() => getNbKeepersForPushNotifications()}
                >
                  Chercher
                </Button>
              )}
            </Col>
          )}
          {keepersAvailable.loaded && (
            <Col xs={6}>
              {keepersAvailable.nbKeepersActive > 0 ? (
                <StatsOutput>
                  ✅ <b>{keepersAvailable.nbKeepersActive} Keepers ont activé les notifications push</b> sur un total de{" "}
                  {keepersAvailable.nbKeepers} Keepers
                </StatsOutput>
              ) : (
                <>
                  <StatsOutput>❌ Aucun Keeper n&apos;a activé la fonctionnalité push sur cette zone</StatsOutput>
                  <br />
                  <Button
                    disabled={statuses.length === 0 || zipcodes.length === 0}
                    onClick={() => getNbKeepersForPushNotifications()}
                  >
                    Chercher
                  </Button>
                </>
              )}
            </Col>
          )}
        </Row>

        {keepersAvailable.loaded && keepersAvailable.nbKeepersActive > 0 && (
          <>
            <Row style={{ marginTop: 20 }}>
              <Col xs={6} style={{ position: "relative" }}>
                <Subtitle>2️⃣ Notification</Subtitle>
                <Input
                  fluid
                  placeholder="Exemple : Annonce incroyable 🤩"
                  onChange={e => setNotificationData({ ...notificationData, title: e.target.value })}
                  className="inputText"
                  label={"Titre"}
                  value={notificationData.title}
                  disabled={testDone}
                />
                <HelpInputText style={{ marginBottom: 10 }}>
                  {notificationData.title.length}/30 caractères max recommandés
                </HelpInputText>
                <Input
                  fluid
                  placeholder="Exemple : Un nouveau transporteur arrive dans votre quartier !"
                  onChange={e => setNotificationData({ ...notificationData, content: e.target.value })}
                  className="inputText"
                  label={"Contenu"}
                  value={notificationData.content}
                  disabled={testDone}
                />
                <HelpInputText>{notificationData.content.length}/140 caractères max recommandés</HelpInputText>

                <Dropdown
                  fluid
                  selection
                  defaultValue={deeplinkToDashboard}
                  options={notificationRedirectionOptions}
                  onChange={(e, { value }) => {
                    setNotificationData({
                      ...notificationData,
                      // toString to handle type error that prevents build
                      data: { redirectTo: value.toString() },
                    })
                  }}
                  style={{ marginTop: 20 }}
                  disabled={testDone}
                />
              </Col>
            </Row>

            {notificationData.title.length > 0 && notificationData.content.length > 0 && (
              <>
                <Row style={{ marginTop: 20 }}>
                  <Col xs={6}>
                    <Subtitle>3️⃣ Tester la notification</Subtitle>
                    Pour tester la notification, il vous faut un compte Keeper connecté sur l&apos;application Pickme,
                    dont l&apos;email est une adresse <b>@mypickme.com</b> et avec les notifications push activées !
                    <br />
                    Si c&apos;est bon, tapez le nom du Keeper pour le trouver ci-dessous
                    <br />
                    <br />
                    <Search
                      onSearch={getTestKeepers}
                      onChange={_onChangeKeepers}
                      placeholder={"🔍 Rechercher un Keeper de test"}
                    />
                    <br />
                  </Col>
                  <Col xs={6} style={{ display: "flex", alignItems: "center" }}>
                    {testDone && `✅ Notification envoyée`}
                  </Col>
                </Row>
                <Button
                  disabled={!testKeeperId || !notificationData.title || !notificationData.content}
                  onClick={() => sendTestNotification()}
                >
                  {testDone ? "Retester" : "Tester"}
                </Button>
                <Button style={{ marginLeft: 10 }} onClick={() => resetForm()} color="red">
                  Recommencer
                </Button>
              </>
            )}

            {testDone && (
              <>
                <Row style={{ marginTop: 20 }}>
                  <Col xs={12}>
                    <Subtitle>4️⃣ Validation</Subtitle>
                    Tout semble prêt pour envoyer la 🚀
                  </Col>
                </Row>
                <br />
                {!notificationSent && (
                  <Button onClick={() => sendPushNotifications()}>
                    Envoyer la notification à {keepersAvailable.nbKeepersActive} Keepers
                  </Button>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default PushNotifications
