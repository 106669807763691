import React, { ReactElement } from "react"
import styled from "styled-components"
import FeatherIcon from "feather-icons-react"

import { AvailabilitySlotType, NormalizedAvailability } from "types/agenda.types"
import { COLOR } from "utils/color"

const AvailabilitySlotWrapper = styled.div<{ selected?: boolean; color?: string }>`
  flex: 1;
  height: 160px;
  max-width: 160px;
  border-width: ${({ selected }) => (selected ? "2px" : "1px")};
  border-style: solid;
  border-radius: 25px;
  border-color: ${({ color, selected }) => (selected ? color : COLOR.SUPER_LIGHT_GREY)};
  align-items: center;
  padding-top: 20px;
  background-color: white;
`
const Paragraph = styled.div<{ bold?: boolean; padded?: boolean }>`
  font-family: ${({ bold = false }) => (bold ? "Poppins-Semibold" : "Poppins-Regular")};
  font-size: 14px;
  color: ${COLOR.DARK_GREY};
  line-height: 22px;
  margin-bottom: ${({ padded = true }) => (padded ? 32 : 0)}px;
`

const AvailabilitySlotContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const AvailibilitySlotParagraph = styled(Paragraph).attrs({ padded: false })<{ color?: string; caption?: boolean }>`
  text-align: center;
  color: ${({ color, caption }) => (caption ? COLOR.MEDIUM_SUPER_LIGHT_GREY : color || "#848484")};
  font-size: ${({ caption }) => (caption ? "10px" : "12px")};
  line-height: ${({ caption }) => (caption ? "12px" : "18px")};
  ${({ caption }) => (caption ? "margin-bottom: 8px" : "")}
  margin: 8px 0;
`

const SLOT_TYPE_PROPS = {
  morning: {
    iconName: "bell",
    title: "Matin",
    subtitle: "9h - 12h",
  },
  noon: {
    iconName: "clock",
    title: "Midi",
    subtitle: "12h - 15h",
  },
  afternoon: {
    iconName: "sun",
    title: "Après-midi",
    subtitle: "15h - 18h",
  },
  evening: {
    iconName: "moon",
    title: "Soirée",
    subtitle: "18h - 21h",
  },
}

const AVAILABILITY_TYPE_PROPS = {
  punctual: {
    color: COLOR.MEDIUM_LIGHT_GREEN,
    caption: "Cette semaine uniquement",
    selected: true,
  },
  recurring: {
    color: COLOR.YELLOW,
    caption: "Se répète toutes les semaines",
    selected: true,
  },
  disabled: {
    color: COLOR.MEDIUM_SUPER_LIGHT_GREY,
    caption: " ",
    selected: false,
  },
}

function getAvailabilityTypeAndIdForSlot({ recurring, punctual }: NormalizedAvailability, slotType: string) {
  // eslint-disable-next-line no-nested-ternary
  return recurring && recurring[slotType]
    ? { availabilityType: "recurring", id: recurring[slotType].id }
    : punctual && punctual[slotType]
    ? { availabilityType: punctual[slotType].endedRecurring ? "recurring" : "punctual", id: punctual[slotType].id }
    : { availabilityType: "disabled" }
}

function AvailabilitySlot({
  slotType,
  availability,
}: {
  slotType: AvailabilitySlotType
  availability: NormalizedAvailability
}): ReactElement {
  const { iconName, title, subtitle } = SLOT_TYPE_PROPS[slotType]
  const { availabilityType } = getAvailabilityTypeAndIdForSlot(availability, slotType)
  const { color, caption, selected } = AVAILABILITY_TYPE_PROPS[availabilityType]

  return (
    <AvailabilitySlotWrapper color={color} selected={selected}>
      <AvailabilitySlotContent>
        <FeatherIcon icon={iconName} size={32} color={color} />
        <AvailibilitySlotParagraph color={color} bold>
          {title}
        </AvailibilitySlotParagraph>
        <AvailibilitySlotParagraph>{subtitle}</AvailibilitySlotParagraph>
      </AvailabilitySlotContent>
      <AvailibilitySlotParagraph caption>{caption}</AvailibilitySlotParagraph>
    </AvailabilitySlotWrapper>
  )
}

const AvailabilitySelectorWrapper = styled.div``

const AvailabilitySelectorRow = styled.div`
  display: flex;
  flex-direction: row;
`

const AvailabilitySelectorSpacer = styled.div<{ horizontal?: boolean }>`
  ${({ horizontal }) => (horizontal ? "height" : "width")}: 12px;
  align-self: stretch;
`

interface AvailabilitySelectorProps {
  availability: NormalizedAvailability
}

export function AvailabilitySelector({ availability }: AvailabilitySelectorProps): ReactElement {
  return (
    <AvailabilitySelectorWrapper>
      <AvailabilitySelectorRow>
        <AvailabilitySlot slotType={AvailabilitySlotType.MORNING} availability={availability} />
        <AvailabilitySelectorSpacer />
        <AvailabilitySlot slotType={AvailabilitySlotType.NOON} availability={availability} />
        <AvailabilitySelectorSpacer />
        <AvailabilitySlot slotType={AvailabilitySlotType.AFTERNOON} availability={availability} />
        <AvailabilitySelectorSpacer />
        <AvailabilitySlot slotType={AvailabilitySlotType.EVENING} availability={availability} />
      </AvailabilitySelectorRow>
    </AvailabilitySelectorWrapper>
  )
}
