import moment from "moment"
import {
  Availability,
  AvailabilityType,
  AvailabilitySlotType,
  NormalizedAvailabilities,
  NormalizedPunctualAvailabilities,
  NormalizedRecurringAvailabilities,
} from "types/agenda.types"
import { UpdatedAvailabilities } from "components/Keeper/Calendar/AgendaSelector"

export function getKeyFromDate(date: string | moment.Moment): string {
  return moment(date).format("YYYY-MM-DD")
}

export function getDayFromDate(date: string): string {
  switch (moment(date).isoWeekday()) {
    case 1:
      return "MON"
    case 2:
      return "TUE"
    case 3:
      return "WED"
    case 4:
      return "THU"
    case 5:
      return "FRI"
    case 6:
      return "SAT"
    case 7:
      return "SUN"
    default:
      return ""
  }
}

export function getSlotTypeFromAvailability({ startTime, endTime }: Availability): AvailabilitySlotType {
  if (startTime === "09:00" && endTime === "12:00") {
    return AvailabilitySlotType.MORNING
  } else if (startTime === "12:00" && endTime === "15:00") {
    return AvailabilitySlotType.NOON
  } else if (startTime === "15:00" && endTime === "18:00") {
    return AvailabilitySlotType.AFTERNOON
  } else if (startTime === "18:00" && endTime === "21:00") {
    return AvailabilitySlotType.EVENING
  }
}

function getRecurringDateKeysFromAvailability({ startDate, endDate }: Availability) {
  const date = moment(startDate)
  const dates = []
  do {
    dates.push(getKeyFromDate(date))
    date.add(7, "day")
  } while (date.isSameOrBefore(endDate))
  return dates
}

export function filterInvalidAvailabilities(agenda: Availability[]): Availability[] {
  /**
   * Rule to filter availabilities to display :
   * 1 - PUNCTUAL : startDate is today or after today
   * 2 - RECURRING : no endDate OR endDate is after today
   */
  return agenda
    ? agenda.filter(date => {
        const c1 = date.type === "PUNCTUAL" && moment().subtract(7, "days").isSameOrBefore(date.startDate, "day")
        const c2 =
          date.type === "RECURRING" &&
          getSlotTypeFromAvailability(date) !== undefined &&
          (!date.endDate || (!!date.endDate && moment().subtract(7, "days").isSameOrBefore(date.endDate, "day")))

        return c1 || c2
      })
    : []
}

export function mergeAvailabilities(
  filteredAgenda: Availability[],
  updatedAvailabilities: UpdatedAvailabilities,
): Availability[] {
  const { created, updated, deleted } = updatedAvailabilities
  return [
    ...filteredAgenda
      .filter(availability => !deleted.find(({ id }) => id === availability.id))
      .map(availability => updated.find(({ id }) => id === availability.id) || availability),
    ...created,
  ]
}

export function getNormalizedAvailabilities(filteredAgenda: Availability[]): NormalizedAvailabilities {
  const punctual = {} as NormalizedPunctualAvailabilities
  const recurring = {} as NormalizedRecurringAvailabilities

  filteredAgenda.forEach(availability => {
    const { id, type, startDate, endDate } = availability
    const slotKey = getSlotTypeFromAvailability(availability)

    if (type === AvailabilityType.PUNCTUAL) {
      punctual[startDate] = {
        ...(punctual[startDate] || {}),
        [slotKey]: { id, endedRecurring: false },
      }
    }

    if (type === AvailabilityType.RECURRING && !!endDate) {
      getRecurringDateKeysFromAvailability(availability).forEach(dateKey => {
        punctual[dateKey] = {
          ...(punctual[dateKey] || {}),
          [slotKey]: { id, endedRecurring: true },
        }
      })
    }

    if (type === AvailabilityType.RECURRING && !endDate) {
      const dayKey = getDayFromDate(startDate)
      const currentStartDate = recurring[dayKey] && (recurring[dayKey][slotKey] as unknown as string)
      const oldestStartDate =
        currentStartDate && moment(startDate).isBefore(currentStartDate, "day") ? currentStartDate : startDate
      recurring[dayKey] = {
        ...(recurring[dayKey] || {}),
        [slotKey]: { id, startDate: oldestStartDate },
      }
    }
  })

  return { punctual, recurring }
}
