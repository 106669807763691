import React, { forwardRef, ReactElement, useImperativeHandle, useMemo, useState } from "react"
import styled from "styled-components"

import { HorizontalCalendar } from "./HorizontalCalendar"
import {
  filterInvalidAvailabilities,
  getDayFromDate,
  getKeyFromDate,
  getNormalizedAvailabilities,
  mergeAvailabilities,
} from "./utils/agenda"
import { AvailabilitySelector } from "./AvailabilitySelector"
import { Availability, NormalizedRecurringAvailability } from "types/agenda.types"
import moment from "moment"

const Wrapper = styled.div``

const PaddedAvailabilitySelector = styled(AvailabilitySelector)`
  margin-top: 2em;
`
export type UpdatedAvailabilities = {
  created: Availability[]
  updated: Availability[]
  deleted: Availability[]
}

const DEFAULT_UPDATED_AVAILABILITIES = {
  created: [],
  updated: [],
  deleted: [],
} as UpdatedAvailabilities

interface AgendaSelectorProps {
  agenda: Availability[]
  onAvailabilitiesUpdated: (availabilities?: UpdatedAvailabilities) => void
}

function AgendaSelectorComponent(
  { agenda, onAvailabilitiesUpdated }: AgendaSelectorProps,
  ref: React.MutableRefObject<any>,
): ReactElement {
  const [selectedDate, setSelectedDate] = useState<string | null>(null)
  const [updatedAvailabilities, setUpdatedAvailabilities] =
    useState<UpdatedAvailabilities>(DEFAULT_UPDATED_AVAILABILITIES)

  /**
   *
   * @param availabilities The updated availabilities
   *
   *
   */
  function _updateAvailabilities(availabilities: UpdatedAvailabilities) {
    setUpdatedAvailabilities(availabilities)

    if (onAvailabilitiesUpdated) {
      const { created, updated, deleted } = availabilities
      if (created.length === 0 && updated.length === 0 && deleted.length === 0) {
        onAvailabilitiesUpdated(undefined)
      } else {
        onAvailabilitiesUpdated(availabilities)
      }
    }
  }

  /**
   * When we save the availabilities, we clear the tmp object "UPDATED_AVAILABILITIES"
   */
  useImperativeHandle(ref, () => ({
    clearUpdatedAvailabilities: () => {
      _updateAvailabilities(DEFAULT_UPDATED_AVAILABILITIES)
    },
  }))

  /**
   * We filter the agenda sent by the API to only use the current availabilities
   * If we have old recurring or punctual availabilities, we filter them
   */
  const filteredAgenda = useMemo(() => filterInvalidAvailabilities(agenda), [agenda])

  /**
   * We normalize availabilities : we have punctuals et recurring availabilities (see NormalizedAvailability type)
   * PUNCTUAL are ordered by Date, which is used as the key
   * RECURRING are ordered by Day (3 letters. ex: MON, TUE, WED, ...), which is used as the key
   * To display them in the agenda, we merge availabilities created in DB and availabilities the Keeper created/updated/deleted at the moment
   */
  const normalized = useMemo(() => {
    const mergedAvailabilities = mergeAvailabilities(filteredAgenda, updatedAvailabilities)
    return getNormalizedAvailabilities(mergedAvailabilities)
  }, [filteredAgenda, updatedAvailabilities])

  /**
   * When a date is selected, we get the availabilities for this day from the normalized data
   */
  const availabilityForDate = useMemo(() => {
    if (normalized.punctual && normalized.recurring && selectedDate) {
      const punctual = normalized.punctual[getKeyFromDate(selectedDate)]
      const recurring = normalized.recurring[getDayFromDate(selectedDate)]

      if (!recurring) return { punctual, recurring }

      const recurringForDate = {
        morning: moment(selectedDate).isSameOrAfter(recurring.morning?.startDate, "day")
          ? recurring.morning
          : undefined,
        noon: moment(selectedDate).isSameOrAfter(recurring.noon?.startDate, "day") ? recurring.noon : undefined,
        afternoon: moment(selectedDate).isSameOrAfter(recurring.afternoon?.startDate, "day")
          ? recurring.afternoon
          : undefined,
        evening: moment(selectedDate).isSameOrAfter(recurring.evening?.startDate, "day")
          ? recurring.evening
          : undefined,
      } as NormalizedRecurringAvailability
      return { punctual, recurring: recurringForDate }
    }
  }, [normalized, selectedDate])

  // function _handleSlotPressed(slotType, availabilityType, availabilityId) {
  //   const isTodayOrTomorrow = moment(selectedDate).isSameOrBefore(moment().endOf("day").add(1, "day"))
  //   const selectedDateKey = getKeyFromDate(selectedDate)
  //   let { created, updated, deleted } = updatedAvailabilities

  //   if (availabilityType === "disabled") {
  //     const deletedPunctualAvailability = deleted.find(
  //       availability =>
  //         availability.startDate === selectedDateKey &&
  //         getSlotTypeFromAvailability(availability) === slotType &&
  //         availability.type === "PUNCTUAL"
  //     )
  //     if (deletedPunctualAvailability) {
  //       deleted = deleted.filter(({ id }) => id !== deletedPunctualAvailability.id)
  //     } else {
  //       created = [...created, createPunctualAvailibility(slotType, selectedDateKey)]
  //     }
  //   }

  //   if (availabilityType === "punctual") {
  //     const createdAvailability = created.find(({ id }) => id === availabilityId)
  //     if (createdAvailability) {
  //       created = created.filter(({ id }) => id !== availabilityId)
  //       const agendaRecurringAvailability = filteredAgenda.find(
  //         ({ id, type }) => id === availabilityId && type === "RECURRING"
  //       )
  //       if (agendaRecurringAvailability) {
  //         if (agendaRecurringAvailability.endDate) {
  //           updated = [...updated, { ...agendaRecurringAvailability, endDate: undefined }]
  //         }
  //       } else {
  //         created = [...created, createRecurringAvailibility(slotType, selectedDateKey)]
  //       }
  //     } else {
  //       if (isTodayOrTomorrow) {
  //         onInvalidAvailibilityPressed && onInvalidAvailibilityPressed()
  //         return
  //       }
  //       const agendaAvailability = filteredAgenda.find(({ id }) => id === availabilityId)
  //       deleted = [...deleted, agendaAvailability]
  //       created = [...created, createRecurringAvailibility(slotType, selectedDateKey)]
  //     }
  //   }

  //   if (availabilityType === "recurring") {
  //     const createdAvailability = created.find(({ id }) => id === availabilityId)
  //     if (createdAvailability) {
  //       created = created.filter(({ id }) => id !== availabilityId)
  //       if (moment(createdAvailability.startDate).isBefore(selectedDate, "day")) {
  //         const dayBeforeSelectedDate = getKeyFromDate(moment(selectedDate).subtract(1, "day"))
  //         created = [...created, { ...createdAvailability, endDate: dayBeforeSelectedDate }]
  //       }
  //     } else {
  //       const agendaAvailability = filteredAgenda.find(({ id }) => id === availabilityId)
  //       updated = updated.filter(({ id }) => id !== availabilityId)
  //       if (moment(agendaAvailability.startDate).isBefore(selectedDate, "day")) {
  //         const dayBeforeSelectedDate = getKeyFromDate(moment(selectedDate).subtract(1, "day"))
  //         updated = [...updated, { ...agendaAvailability, endDate: dayBeforeSelectedDate }]
  //       } else {
  //         if (isTodayOrTomorrow) {
  //           onInvalidAvailibilityPressed && onInvalidAvailibilityPressed()
  //           return
  //         }
  //         deleted = [...deleted, { ...agendaAvailability }]
  //       }
  //     }
  //   }

  // _updateAvailabilities({ created, updated, deleted })
  // }

  return (
    <Wrapper>
      <HorizontalCalendar
        onDateSelected={setSelectedDate}
        activatedDates={normalized.punctual}
        activatedDays={normalized.recurring}
      />
      {availabilityForDate && <PaddedAvailabilitySelector availability={availabilityForDate} />}
    </Wrapper>
  )
}

export const AgendaSelector = forwardRef(AgendaSelectorComponent)
