import { Button } from "semantic-ui-react"
import React from "react"
import styled from "styled-components"
import RolesGuard from "components/RolesGuard"
import { AdminUserRole } from "types/admin-user.types"
import moment from "moment"

const Description = styled.p`
  font-size: 13px;
`

interface BankAccountStatusProps {
  isStripeValidated: boolean
  setBankAccountStatus: (isEnabled: boolean) => void
  updatedAt: Date
}

const BanKAccountStatus: React.FC<BankAccountStatusProps> = ({
  isStripeValidated,
  setBankAccountStatus,
  updatedAt,
}) => {
  const checkIsUpdatedWithinLast48Hours = (updatedAt: Date) => {
    const now = moment.utc()
    const updatedAtTime = moment.utc(updatedAt)
    const differenceInHours = now.diff(updatedAtTime, "hours")
    return differenceInHours < 48
  }

  const isUpdatedWithinLast48Hours = checkIsUpdatedWithinLast48Hours(updatedAt)

  return isStripeValidated ? (
    <>
      <Description>✅ Compte Stripe validé</Description>
      {isUpdatedWithinLast48Hours && <p>Le compte bancaire a été mis à jour il y a moins de 48 heures.</p>}
      <RolesGuard
        allowedRoles={[
          AdminUserRole.HEAD_OF_CARE,
          AdminUserRole.CARE_MANAGER,
          AdminUserRole.CARE_SENIOR,
          AdminUserRole.ADMIN,
        ]}
      >
        <p>
          <Button onClick={() => setBankAccountStatus(false)}>Invalider le compte bancaire</Button>
        </p>
      </RolesGuard>
    </>
  ) : (
    <>
      <Description>❌ Compte bancaire en cours de validation ou rejeté par Stripe.</Description>
      {isUpdatedWithinLast48Hours && <p>Le compte bancaire a été mis à jour il y a moins de 48 heures.</p>}
      <RolesGuard
        allowedRoles={[
          AdminUserRole.HEAD_OF_CARE,
          AdminUserRole.CARE_MANAGER,
          AdminUserRole.CARE_SENIOR,
          AdminUserRole.ADMIN,
        ]}
      >
        <p>
          <Button onClick={() => setBankAccountStatus(true)}>Valider le compte bancaire</Button>
        </p>
      </RolesGuard>
    </>
  )
}
export default BanKAccountStatus
