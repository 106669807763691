export enum AvailabilityTime {
  NINE = "09:00",
  TWELVE = "12:00",
  FIVETEEN = "15:00",
  EIGHTEEN = "18:00",
  TWENTYONE = "21:00",
}

export enum AvailabilitySlotType {
  MORNING = "morning",
  NOON = "noon",
  AFTERNOON = "afternoon",
  EVENING = "evening",
}

export enum AvailabilityType {
  PUNCTUAL = "PUNCTUAL",
  RECURRING = "RECURRING",
}

export interface Availability {
  id: string
  addressId: string
  type: AvailabilityType
  startDate: string // "2021-05-04"
  endDate: string | null // "2021-05-04"
  startTime: AvailabilityTime
  endTime: AvailabilityTime
  recurring: string[]
  createdAt: string
  updatedAt: string
}

/**
 * PUNCTUAL NORMALIZED
 */
export interface NormalizedPunctualAvailability {
  morning?: {
    id: string
    endedRecurring: boolean
  }
  noon?: {
    id: string
    endedRecurring: boolean
  }
  afternoon?: {
    id: string
    endedRecurring: boolean
  }
  evening?: {
    id: string
    endedRecurring: boolean
  }
}
export interface NormalizedPunctualAvailabilities {
  [date: string]: NormalizedPunctualAvailability
}

/**
 * RECURRING NORMALIZED
 */
export interface NormalizedRecurringAvailability {
  morning?: {
    id: string
    startDate: string
  }
  noon?: {
    id: string
    startDate: string
  }
  afternoon?: {
    id: string
    startDate: string
  }
  evening?: {
    id: string
    startDate: string
  }
}

export interface NormalizedRecurringAvailabilities {
  [day: string]: NormalizedRecurringAvailability
}

export interface NormalizedAvailability {
  punctual: NormalizedPunctualAvailability
  recurring: NormalizedRecurringAvailability
}

export interface NormalizedAvailabilities {
  punctual: NormalizedPunctualAvailabilities
  recurring: NormalizedRecurringAvailabilities
}
