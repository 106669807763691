import React, { useState, useCallback, useMemo, useEffect, ReactElement } from "react"
import moment from "moment"
import styled from "styled-components"
import FlatList from "flatlist-react"

import { getDayFromDate, getKeyFromDate } from "components/Keeper/Calendar/utils/agenda"
import { NormalizedPunctualAvailabilities, NormalizedRecurringAvailabilities } from "types/agenda.types"
import { COLOR } from "utils/color"

const CalendarWrapper = styled.div``

const CalendarDayItemWrapper = styled.div<{ selected: boolean }>`
  min-width: 36px;
  padding: 8px;
  margin: 3px 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? COLOR.MEDIUM_LIGHT_GREEN : "transparent")};
`
const Paragraph = styled.div<{ bold?: boolean; padded?: boolean }>`
  font-family: ${({ bold = false }) => (bold ? "Poppins-Semibold" : "Poppins-Regular")};
  font-size: 14px;
  color: ${COLOR.DARK_GREY};
  line-height: 22px;
  margin-bottom: ${({ padded = true }) => (padded ? 32 : 0)}px;
`

const MonthParagraph = styled(Paragraph).attrs({ padded: false })`
  color: ${COLOR.MEDIUM_SUPER_LIGHT_GREY};
  font-size: 12px;
  margin-horizontal: 32px;
`

const DateParagraph = styled(Paragraph).attrs({ padded: false })<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? COLOR.WHITE : COLOR.MEDIUM_SUPER_LIGHT_GREY)};
`

const ActivatedDot = styled.div<{ selected: boolean; activated: boolean }>`
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: ${({ selected }) => (selected ? COLOR.WHITE : COLOR.LIGHT_GREY)};
  opacity: ${({ activated }) => (activated ? 1 : 0)};
`

interface CalendarDateItemProps {
  date: string
  selected: boolean
  activated: boolean
  onClick: (date: string) => void
}

function CalendarDateItem({ date, selected, activated, onClick }: CalendarDateItemProps): ReactElement {
  const momentDate = moment(date)
  return (
    <CalendarDayItemWrapper selected={selected} onClick={() => onClick(date)}>
      <DateParagraph selected={selected}>{momentDate.format("dd")}</DateParagraph>
      <DateParagraph bold selected={selected}>
        {momentDate.format("D")}
      </DateParagraph>
      <ActivatedDot activated={activated} selected={selected} />
    </CalendarDayItemWrapper>
  )
}

function generateCalendarDates(startDate: moment.Moment | string, count: number, includeStartDate = false): string[] {
  const date = moment(startDate).endOf("day")
  return [...Array(count)].map((_, index) =>
    (includeStartDate && index === 0 ? date : date.add(1, "day")).toISOString(),
  )
}

interface HorizontalCalendarProps {
  onDateSelected: (date: string) => void
  activatedDates: NormalizedPunctualAvailabilities
  activatedDays: NormalizedRecurringAvailabilities
}

export function HorizontalCalendar({
  onDateSelected,
  activatedDates,
  activatedDays,
}: HorizontalCalendarProps): ReactElement {
  const daysBeforeToday = 7 // Show 7 days before today
  const daysAfterToday = 15 // Show 15 days after today
  const [calendarDates, setCalendarDates] = useState(() =>
    generateCalendarDates(moment().subtract(daysBeforeToday, "days"), daysAfterToday + daysBeforeToday, true),
  )
  const [selectedDate, setSelectedDate] = useState(calendarDates[daysBeforeToday])
  const [firstViewableDate, setFirstViewableDate] = useState(calendarDates[daysBeforeToday])

  useEffect(() => {
    onDateSelected && onDateSelected(calendarDates[daysBeforeToday])
  }, [onDateSelected, calendarDates[0]])

  const currentMonth = useMemo(() => moment(firstViewableDate).format("MMMM YYYY").toUpperCase(), [firstViewableDate])

  const dateItems = useMemo(() => {
    return calendarDates.map(date => {
      const day = getDayFromDate(date) || ""
      const recurringDay = activatedDays && activatedDays[day]
      const activated =
        (activatedDates && !!activatedDates[getKeyFromDate(date)]) ||
        (recurringDay &&
          !!Object.values(recurringDay).find(({ startDate }) => moment(date).isSameOrAfter(startDate, "day")))
      return { date, activated }
    })
  }, [calendarDates, activatedDates, activatedDays])

  const _handleViewableItemsChanged = useCallback(({ viewableItems }) => {
    const itemInfo = viewableItems.find(({ isViewable }) => isViewable)
    itemInfo && itemInfo.item && setFirstViewableDate(itemInfo.item.date)
  }, [])

  function _handleDateSelected(date) {
    setSelectedDate(date)
    onDateSelected && onDateSelected(date)
  }

  function _renderDateItem(item) {
    return (
      <CalendarDateItem
        date={item.date}
        key={item.date}
        selected={item.date === selectedDate}
        activated={item.activated}
        onClick={_handleDateSelected}
      />
    )
  }

  function _handleListEndReached() {
    setCalendarDates(oldCalendarDays => [
      ...oldCalendarDays,
      ...generateCalendarDates(oldCalendarDays[oldCalendarDays.length - 1], 60),
    ])
  }

  return (
    <CalendarWrapper>
      <MonthParagraph>{currentMonth}</MonthParagraph>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <FlatList
          contentContainerStyle={{ paddingLeft: 32 }}
          list={dateItems}
          renderItem={_renderDateItem}
          onViewableItemsChanged={_handleViewableItemsChanged}
          onEndReached={_handleListEndReached}
          onEndReachedThreshold={0.5}
          showsHorizontalScrollIndicator={false}
          horizontal
        />
      </div>
    </CalendarWrapper>
  )
}
