import moment from "moment"
import { DayAgenda } from "types/keeper.types"

/**
 * Get business dates (without weekend) for delivering based on a startDate
 *
 * @param startDate - String, Date format YYYY-MM-DD
 * @param numberOfDaysToAdd - Number, number of days to add to startDate
 *
 * @returns { startDate: string; endDate: string }
 *
 */
const getBusinessDays = (startDate: string, numberOfDaysToAdd: number): { startDate: string; endDate: string } => {
  const endDate = moment(startDate)
  let days = 1 // startDate is always tomorrow, so we start at 1

  while (days < numberOfDaysToAdd) {
    endDate.add(1, "days")
    // Saturday = 6, Sunday = 0
    if (endDate.weekday() !== 6 && endDate.weekday() !== 0) {
      days++
    }
  }

  return {
    startDate: moment(startDate).format("YYYY-MM-DD"),
    endDate: endDate.format("YYYY-MM-DD"),
  }
}

/**
 *
 * [AGENDA FILTERS PROCESS]
 * Apply filters to an Address Agenda and returns if the Agenda complies with the filters.
 *
 * @param agenda - The computed availabilities of an Address
 * @param filter - The filter object
 * @param filter.startDate - The start date from which we will check if the availabilities
 * @param filter.numberDaysToCheck - How many days (including the start date) we apply the filters
 * @param filter.startHour - The time range start
 * @param filter.endHour - The time range end
 * @param filter.numberOfAvailabilitiesNeeded - For a given time range, how many availabilities does the Address need ?
 *
 * @returns true if the Agenda is "available" with the filters applied
 *
 */

export const checkAgenda = (
  agenda: DayAgenda[],
  filter: {
    startDate: string
    numberDaysToCheck: number
    startHour: string
    endHour: string
    numberOfAvailabilitiesNeeded: number
  },
): boolean => {
  const agendaWithoutWeekend = agenda.filter(agendaData => {
    const weekDay = moment(agendaData.date).weekday()
    // Remove weekEndDays (Saturday/Sunday)
    if (weekDay !== 6 && weekDay !== 0) {
      return true
    }
  })

  // We only want to apply filters on the N days to check
  const dates = getBusinessDays(filter.startDate, filter.numberDaysToCheck)

  const agendaFiltered = agendaWithoutWeekend.filter(
    agendaData =>
      moment(agendaData.date).isSameOrAfter(moment(dates.startDate)) &&
      moment(agendaData.date).isSameOrBefore(moment(dates.endDate)),
  )

  // For every day between the start date and the number of days to check,
  // we check if all timeRanges have the numberOfAvailabilities required
  const isAgendaValid = agendaFiltered.every(agendaData => {
    // Get only timesRanges between wanted hours
    const agendaBetweenHours = agendaData.timeRanges.filter(
      timeRange =>
        // Remove ":" from "19:00" for example, to get a number and compare them
        timeRange.start.replace(":", "") >= filter.startHour.replace(":", "") &&
        timeRange.end.replace(":", "") <= filter.endHour.replace(":", ""),
    )
    return agendaBetweenHours.length >= filter.numberOfAvailabilitiesNeeded
  })

  return isAgendaValid
}
